'use strict'

require("bootstrap-sass/assets/javascripts/bootstrap")
require("bootstrap-fileinput/js/fileinput")
require("bootstrap-waitingfor/build/bootstrap-waitingfor")
require("bootstrap-colorpickersliders/dist/bootstrap.colorpickersliders")

require("sugar/release/sugar-full.development")

// select2 の CSS
require("select2/select2")                       // select2 本体のCSSの読み込み

// select2 の JS
import Select2 from "select2"

// エラーメッセージの日本語化
require("select2/select2_locale_ja")

import './common/base'
import './scaffolds'

window.tinycolor = require("tinycolor2")
window.autosize  = require('autosize')

import * as Routes from '../routes.js.erb';
// If you want you can make it public:
window.Routes = Routes;

require("jquery-ujs/src/rails.js")