/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {

  // ただし、" や ' は escape しないらしい。
  window.escapeHTML = text => $('<div/>').text(text).html();

  //
  // 仕入先情報のセレクトボックス生成用のjQuery Function
  //
  $.fn.select2_suppliers = function(options) {
    const defaultOptions = {
      minimumInputLength: 1,
      ajax: {
        type: 'GET',
        dataType: 'json',
        quietMillis: 250,
        url: '/suppliers/retrieve',
        data(term, page) { return { name: term }; },
        results(data, page) { return { results: data }; }
      },

      initSelection(element, callback) {
        const id = $(element).val();
        if (!id) { return; }
        return $.ajax('/suppliers/' + id, {
          dataType: 'json'
        }).done( data => callback(data));
      },

      formatResult(item) { return `<div>${escapeHTML(item.name)}</div>`; },
      formatSelection(item) { return escapeHTML(item.name); },
      escapeMarkup(m) { return m; }
    };

    options = $.extend(true, {}, defaultOptions, options);
    return $(this).each( function() { return $(this).select2(options);  });
  };


  //
  // 送料情報のセレクトボックス生成用のjQuery Function
  //
  return $.fn.select2_shippings = function(options) {
    const defaultOptions = {
      minimumInputLength: 0,
      ajax: {
        type: 'GET',
        dataType: 'json',
        quietMillis: 250,
        url: Routes.retrieve_shippings_path(),
        data(term, page)    { return { name: term }; },
        results(data, page) { return { results: data }; }
      },

      initSelection(element, callback) {
        const id = $(element).val();
        if (!id) { return; }
        return $.ajax(Routes.shipping_path(id), {
          dataType: 'json',
          type: 'GET'
        }).done( data => callback(data));
      },

      formatResult(item) { return `<div>${escapeHTML(item.name)}(${item.created_at}) ${escapeHTML(item.note)}</div>`; },
      formatSelection(item) { return escapeHTML(item.name); },
      escapeMarkup(m) { return m; }
    };

    options = $.extend(true, {}, defaultOptions, options);
    return $(this).each( function() { return $(this).select2(options);  });
  };
});


