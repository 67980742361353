/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const autosize = require('autosize');

$(function() {
  $('[data-toggle="tooltip"]').tooltip();

  $('[data-toggle="tooltip-danger"]').tooltip({
    template: [
      '<div class="tooltip tooltip-danger" role="tooltip">',
      '  <div class="tooltip-arrow"></div>',
      '  <div class="tooltip-inner"></div>',
      '</div>'
    ].join('')
  }).tooltip('show');

  $('[data-toggle="popover"]').popover();

  // メッセージは3秒で削除する
  const $main_message = $("#main-message");
  if ($main_message.find(".alert")) {
    setTimeout(() => $main_message.empty()
    , 3000);
  }

  return autosize($('textarea'));
});

// 消費税計算
window.calcTax = price => ogRound( price *  (1 + TAX_RATE ));

window.calcTaxWithRate = (price, rate) => ogRound( price *  (1 + rate ));


// オリーブガーデンさんの小数点計算（小数点第三位を四捨五入）
window.ogRound = price => Math.round( price * 100 ) / 100;

// 1000.0 を 1000 にする
window.cutoff0 = dec => Number(dec).toFixed(0);

// excel の mround 相当の処理
window.mround = (num, unit) => Math.round(num/unit)*unit;

window.convertFullToHalf = string => string.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248)).replace(/[‐－―]/g, '-').replace(/／/g, '/');

// 追跡番号変換
window.convertTrackingNo = function(tracking_no, textbox, show_error) {
  if (show_error == null) { show_error = true; }
  const unchanged = tracking_no;
  // 全角文字を半角に変換
  tracking_no = tracking_no.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248)).replace(/[‐－―]/g, '-').replace(/／/g, '/').trim();

  // ゆうパケット用の変換(Aを消す)
  if (tracking_no.match(/A[0-9]{12}A/)) {
    tracking_no = tracking_no.replace(/A/g, '');
  }

  // ハイフン有りの変換(-を消す)
  if (tracking_no.match(/[0-9]{4}\-[0-9]{4}\-[0-9]{4}/)) {
    tracking_no = tracking_no.replace(/-/g, '');
  }

  textbox.val(tracking_no);

  // 11桁以上の数字は全てチェックする
  const splited = tracking_no.split(/\//);
  for (let token of Array.from(splited)) {
    var msg;
    if (token.match(/^[0-9]{6,9}$/)) {
      msg = "トラッキング番号が不正です";
      throw { message:msg };
    }
    if (token.match(/[0-9]{11,14}/)) {
      const num = parseInt(token.substring(0, token.length - 1));
      const check_digit = parseInt(token.slice(-1));
      if (check_digit !== (num % 7)) {
        msg = "トラッキング番号が不正です";
        if (show_error) {
          textbox.css('color', 'red');
          textbox.data('container', 'body');
          textbox.data('toggle', 'popover');
          textbox.data('trigger', 'hover');
          textbox.data('placement', 'top');
          textbox.data('content', msg);
          textbox.popover('show');
        }
        throw { message:msg };
      }
    }
  }

  if (show_error) {
    textbox.css('color', '');
    textbox.popover('destroy');
  }

  return tracking_no;
};

// Shift-JIS用のバイト数チェック
window.stringShiftJisBytes = function(string) {
  let length = 0;
  for  (let i = 0, end = string.length, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
    const c = string.charCodeAt(i);
    if (((c >= 0x0) && (c < 0x81)) || (c === 0xf8f0) || ((c >= 0xff61) && (c < 0xffa0)) || ((c >= 0xf8f1) && (c < 0xf8f4))) {
      length += 1;
    } else {
      length += 2;
    }
  }
  return length;
};